import React, { useState, useEffect } from "react";
import { usePrefences } from "../../context/PrefencesContext";
import { useConfig } from "../../context/ConfigContext";
import TreeMain from "../../components/Tree/TreeMain";
import Alert from "../../components/Alert";
import { ArrowRightCircleIcon, CalendarIcon } from "@heroicons/react/24/solid";

import PaginationClassic from "../../components/PaginationClassic";
import TreeMainNav from "../../components/Tree/TreeMainNav";
import Requests from "../../helpers/Requests";
import {
  addDays,
  checkRole,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

import ShopTooltip from "../../components/ShopTooltip";
import DropdownCard from "../../components/Search/DropdownCard";
import DropdownFilter from "../../components/DropdownFilter";
import FinanceIcons from "../../components/Finance/FinanceIcons";
import TransactionsTotals from "../../components/TotalsFooter/TransactionsTotals";
import { useNavigate, Link } from "react-router-dom";
import DropdownCasinoGame from "../../components/Search/DropdownCasinoGame";

function BalanceTransactions({ selCard = undefined }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !(
        checkRole(
          ConfigProvider.config.user,
          "FinanceBalanceTransactionsList"
        ) | checkRole(ConfigProvider.config.user, "CardBalanceTransactionsList")
      )
    ) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [formData, setFormData] = useState({
    date1: formatDate(getDate(), true, false),
    date2: formatDate(addDays(getDate(), 1), true, false),
    card: selCard ? selCard : null,
    page: 1,
    sort: 1,
    sortBy: 1,
    stat: "1",
    type: [],
    provider: [],
    casinoGameSearch: null,
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [totals, setTotals] = useState([]);
  const [showTotals, setShowTotals] = useState(false);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.total = false;
      Requests.postData("/accounting/getBalanceReports", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotal(null);
              setTotals([]);
              setShowTotals(false);
              setTotalItems(res.c);
            }

            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  const [totalsLoading, setTotalsLoading] = useState(false);
  const getTotals = () => {
    if (!loading && !totalsLoading) {
      setTotalsLoading(true);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.total = true;
      Requests.postData("/accounting/getBalanceReports", postData)
        .then((response) => {
          let res = response.data;
          if (diagnosticResponse(res) && res.type === "success") {
            setTotal({
              total: res.total,
              income: res.totalIncome,
              expense: res.totalExpense,
            });
            setTotals(res.totals);
          }
          setTotalsLoading(false);
        })
        .catch(() => {
          setTotalsLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  const allTypes = {
    0: ConfigProvider.getTranslation("Payment"),
    1: ConfigProvider.getTranslation("SportsBets"),
    2: ConfigProvider.getTranslation("Casino"),
    3: ConfigProvider.getTranslation("LiveCasino"),
    4: ConfigProvider.getTranslation("Jackpot"),
    5: ConfigProvider.getTranslation("SendMoney"),
    6: ConfigProvider.getTranslation("ReceiveMoney"),
    7: ConfigProvider.getTranslation("PaymentMethods"),
    8: `${ConfigProvider.getTranslation(
      "Discount"
    )}/${ConfigProvider.getTranslation("NoWageringBonus")}`,
    9: ConfigProvider.getTranslation("BonusWagering"),
    10: ConfigProvider.getTranslation("Freespin"),
  };
  const [allProviders, setAllProviders] = useState({});

  useEffect(() => {
    if (
      ConfigProvider.config.user.auth === "0" &&
      checkRole(
        ConfigProvider.config.user,
        "FinanceBalanceTransactionsProviders"
      ) &&
      Object.keys(allProviders).length === 0
    ) {
      getProviders();
    }
    // eslint-disable-next-line
  }, []);
  const getProviders = () => {
    Requests.postData("/general/getAllProviders", {})
      .then((response) => {
        let res = response.data;
        if (res.type === "success") {
          setAllProviders(res.data);
        }
      })
      .catch(() => {});
  };

  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        !selCard &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("BalanceTransactions")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 grow flex flex-col sm:flex-row sm:mb-0 gap-4 mr-2">
                {!selCard && (
                  <DropdownCard
                    setSelectedItem={(val) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          card: val,
                        };
                      });
                    }}
                  />
                )}
                <DropdownCasinoGame
                  setSelectedItem={(val) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        casinoGameSearch: val,
                      };
                    });
                  }}
                />
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                <input
                  id="date1"
                  name="date1"
                  type="datetime-local"
                  value={formData.date1}
                  onChange={(e) => {
                    return setFormData({ ...formData, date1: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartDate")}
                  disabled={loading}
                  required
                />
                <input
                  id="date2"
                  name="date2"
                  type="datetime-local"
                  value={formData.date2}
                  onChange={(e) => {
                    return setFormData({ ...formData, date2: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("EndDate")}
                  disabled={loading}
                  required
                />
                <DropdownFilter align="right">
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Type")}
                  </div>
                  <ul className="mb-4">
                    {Object.keys(allTypes).map((item) => (
                      <li key={item} className="py-1 px-3">
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item}
                            name={item}
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.type.includes(item)}
                            onChange={() =>
                              setFormData((prev) => {
                                if (prev.type.includes(item)) {
                                  return {
                                    ...prev,
                                    type: prev.type.filter(
                                      (val) => val !== item
                                    ),
                                  };
                                } else {
                                  return {
                                    ...prev,
                                    type: [...prev.type, item],
                                  };
                                }
                              })
                            }
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {allTypes[item]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  {checkRole(
                    ConfigProvider.config.user,
                    "FinanceBalanceTransactionsProviders"
                  ) && (
                    <>
                      <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                        {ConfigProvider.getTranslation("Provider")}
                      </div>
                      <ul className="mb-4">
                        {Object.keys(allProviders).map((item) => (
                          <li key={item} className="py-1 px-3">
                            <label className="flex items-center cursor-pointer">
                              <input
                                id={item}
                                name={item}
                                type="checkbox"
                                className="form-checkbox"
                                checked={formData.provider.includes(item)}
                                onChange={() =>
                                  setFormData((prev) => {
                                    if (prev.provider.includes(item)) {
                                      return {
                                        ...prev,
                                        provider: prev.provider.filter(
                                          (val) => val !== item
                                        ),
                                      };
                                    } else {
                                      return {
                                        ...prev,
                                        provider: [...prev.provider, item],
                                      };
                                    }
                                  })
                                }
                                disabled={loading}
                              />
                              <span className="text-sm font-medium ml-2">
                                {allProviders[item]}
                              </span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </DropdownFilter>
              </div>
            </div>

            {/* Table */}
            <BalanceTransactionsTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
              selCard={selCard}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />
              <div className="my-2">
                {!showTotals && (
                  <button
                    type="button"
                    onClick={() => {
                      setShowTotals(!showTotals);
                      if (!total) {
                        getTotals();
                      }
                    }}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  >
                    {ConfigProvider.getTranslation("Totals")}
                  </button>
                )}
                {showTotals && total ? (
                  <div className="lg:flex gap-2">
                    <span className="text-emerald-500">{`${ConfigProvider.getTranslation(
                      "Income"
                    )}: ${total.income}`}</span>
                    <span className="text-red-500">{`${ConfigProvider.getTranslation(
                      "Expense"
                    )}: ${total.expense}`}</span>
                    <span>{`${ConfigProvider.getTranslation("NET")}: ${
                      total.total
                    }`}</span>
                  </div>
                ) : (
                  <></>
                )}
                {showTotals && total ? (
                  <div className="lg:flex gap-2">
                    {totals.map((item, index) => (
                      <TransactionsTotals key={index} item={item} />
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  ) : null;
}
function BalanceTransactionsTable({
  data,
  loading,
  formData,
  setFormData,
  selCard,
}) {
  const ConfigProvider = useConfig();

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("BalanceTransactions")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-left">#</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Username")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[200px] w-px">
                  <div className="font-semibold text-left">
                    {ConfigProvider.getTranslation("Transaction")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Date")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Prev.Balance")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Amount")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={5}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("BonusAmount")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Next.Balance")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>
              </tr>
            </thead>

            {!loading && data && data.length === 0 && (
              <tbody className="text-sm">
                <tr>
                  <td colSpan="11">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading ? (
              <tbody className="text-sm">
                <tr>
                  <td colSpan="11" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            ) : (
              data.map((item) => {
                return (
                  <BalanceTransactionsTableItem
                    key={item.id}
                    item={item}
                    selCard={selCard}
                  />
                );
              })
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
function BalanceTransactionsTableItem({ item, selCard }) {
  const PrefencesProvider = usePrefences();
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/accounting/getBalanceTransactionDetail", {
        id: item.id,
      })
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res) &&
            res.type === "success" &&
            setData({ data1: res.data1, data2: res.data2 });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (descriptionOpen && Object.keys(data).length === 0) {
      getItems();
    }
    // eslint-disable-next-line
  }, [descriptionOpen]);
  const ConfigProvider = useConfig();
  return (
    <tbody className="text-sm">
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{item.id}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {!selCard ? (
            <Link
              to={"/cards/card/view/" + item.aid}
              className="text-sky-500 dark:text-sky-400 font-medium text-left"
            >
              {item.uname}
            </Link>
          ) : (
            <div className="text-sky-500 dark:text-sky-400 font-medium text-left">
              {item.uname}
            </div>
          )}
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <FinanceIcons creditDetail={false} item={item} />
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-pre-line">
          <div className="text-left">
            {item.desc}
            {item.note.length > 0 ? (
              <>
                <br />
                {`${ConfigProvider.getTranslation("Note")}: ${item.note}`}
              </>
            ) : null}
            {item.performedBy ? (
              <span className="text-xs text-slate-700 dark:text-slate-500">{` (${item.performedBy})`}</span>
            ) : null}
          </div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {ConfigProvider.config.isShopSystem && (
            <ShopTooltip
              size="lg"
              position="left"
              bg="dark"
              shopName=""
              shop={item.shop}
              auth="2"
            />
          )}
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center justify-center w-full text-xs 2xl:text-sm dark:text-slate-400">
            <CalendarIcon className="w-4 h-4 mr-1" />
            {new Intl.DateTimeFormat("default", {
              day: "numeric",
              month: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }).format(new Date(item.date))}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center text-slate-700 dark:text-slate-500">
            {`${item.preBalance} ${item.currency}`}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={`text-center ${
              !item.isNegative ? "text-emerald-500" : "text-rose-500"
            }`}
          >
            {`${item.strAmount} ${item.currency}`}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={`text-center ${
              item.bonusAmount === 0
                ? ""
                : item.bonusAmount > 0
                ? "text-emerald-500"
                : "text-rose-500"
            }`}
          >
            {`${item.strBonusAmount} ${item.currency}`}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center text-slate-800 dark:text-slate-400">
            {`${item.newBalance} ${item.currency}`}
          </div>
        </td>
        <td className="first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            {(item.pmt === null && item.cid === null) |
            !checkRole(
              ConfigProvider.config.user,
              "FinanceBalanceTransactionDetail"
            ) ? (
              <>&nbsp;</>
            ) : (
              <button
                type="button"
                className={`text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 ${
                  descriptionOpen && "rotate-180"
                }`}
                aria-expanded={descriptionOpen}
                onClick={() => setDescriptionOpen(!descriptionOpen)}
                aria-controls={`description-${item.id}`}
              >
                <span className="sr-only">Menu</span>
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                  <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                </svg>
              </button>
            )}
          </div>
        </td>
      </tr>
      {descriptionOpen && (
        <tr
          id={`description-${item.id}`}
          role="region"
          className={`${!descriptionOpen && "hidden"}`}
        >
          <td
            colSpan="11"
            className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3"
          >
            <div className="flex flex-col items-center bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
              {!loading && data.data1 && (
                <div className="p-5 w-full grid grid-cols-2 items-center gap-4">
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      Round Id
                    </label>
                    <label className="block text-sm w-full">
                      {data.data1.roundid ? data.data1.roundid : <>&nbsp;</>}
                    </label>
                  </div>

                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("Date")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data1.date ? (
                        new Intl.DateTimeFormat("default", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          timeZone: PrefencesProvider.prefences.timezone,
                        }).format(new Date(data.data1.date)) +
                        "." +
                        new Date(data.data1.date).getMilliseconds()
                      ) : (
                        <>&nbsp;</>
                      )}
                    </label>
                  </div>
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("Amount")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data1.amount ? data.data1.amount : <>&nbsp;</>}
                    </label>
                  </div>
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("TransactionId")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data1.transactionid ? (
                        data.data1.transactionid
                      ) : (
                        <>&nbsp;</>
                      )}
                    </label>
                  </div>
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("Game")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data1.displayName ? (
                        data.data1.displayName
                      ) : (
                        <>&nbsp;</>
                      )}
                    </label>
                  </div>
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("Provider")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data1.provider ? data.data1.provider : <>&nbsp;</>}
                    </label>
                  </div>
                  {ConfigProvider.config.user.auth === "0" &&
                    checkRole(
                      ConfigProvider.config.user,
                      "FinanceBalanceTransactionsProviders"
                    ) && (
                      <div className="relative">
                        <label className="block text-sm font-medium mb-1 text-white">
                          {ConfigProvider.getTranslation("RootProvider")}
                        </label>
                        <label className="block text-sm w-full">
                          {data.data1.provider ? (
                            data.data1.rootProvider
                          ) : (
                            <>&nbsp;</>
                          )}
                        </label>
                      </div>
                    )}
                </div>
              )}
              {!loading && data.data2 && (
                <div className="p-5 w-full grid grid-cols-2 items-center gap-4">
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("PaymentMethod")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data2.name ? data.data2.name : <>&nbsp;</>}
                    </label>
                  </div>
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("Amount")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data2.amount ? (
                        `${data.data2.amount} ${data.data2.currency}`
                      ) : (
                        <>&nbsp;</>
                      )}
                    </label>
                  </div>
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("Date")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data2.date ? data.data2.date : <>&nbsp;</>}
                    </label>
                  </div>

                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("Confirm")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data2.confirm ? data.data2.confirm : <>&nbsp;</>}
                    </label>
                  </div>
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1 text-white">
                      {ConfigProvider.getTranslation("Details")}
                    </label>
                    <label className="block text-sm w-full">
                      {data.data2.extra &&
                        Object.keys(data.data2.extra).map((extraKey) => (
                          <p
                            key={extraKey}
                          >{`${extraKey}: ${data.data2.extra[extraKey]}`}</p>
                        ))}
                    </label>
                  </div>
                </div>
              )}
            </div>
            {!loading && data.data1 && data.data1.log && (
              <div className="flex flex-col items-center bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
                <div className="p-5 w-full grid grid-cols-2 items-center gap-4">
                  {data.data1.log && (
                    <div className="relative col-span-2">
                      <label className="block text-sm font-medium text-white mb-3 text-lg">
                        {ConfigProvider.getTranslation("RequestLog")}
                      </label>
                      <div className="flex flex-col space-y-4 text-sm w-full divide-y-2  divide-slate-200 dark:divide-slate-700">
                        {Object.keys(data.data1.log).map((item, index) => (
                          <div
                            key={index}
                            className="flex min-w-0 flex-col gap-2 p-2"
                          >
                            <div className="dark:text-slate-200">{item}:</div>
                            <div className="overflow-auto">
                              {data.data1.log[item]}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </td>
        </tr>
      )}
    </tbody>
  );
}
export default BalanceTransactions;
