import React, { useEffect, useState } from "react";
import {
  ArrowRightCircleIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  CalendarIcon,
} from "@heroicons/react/24/solid";
import { useConfig } from "../context/ConfigContext";
import { usePrefences } from "../context/PrefencesContext";
import Requests from "../helpers/Requests";
import {
  addDays,
  checkRole,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../helpers/Helpers";
import TreeMain from "../components/Tree/TreeMain";
import SearchFilter from "../components/SearchFilter";
import PaginationClassic from "../components/PaginationClassic";
import TreeMainNav from "../components/Tree/TreeMainNav";
import Alert from "../components/Alert";
import SortByItem from "../components/SortByItems";
import LoadingSpinner from "../components/Loading/LoadingSpinner";
import ShopTooltip from "../components/ShopTooltip";
import DropdownFilter from "../components/DropdownFilter";
import DropdownCard from "../components/Search/DropdownCard";
import NoData from "../components/NoData";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTimezone } from "../context/TimezoneContext";

function Logs({ selCard = undefined, selAdmin = undefined }) {
  const TimezoneProvider = useTimezone();
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const { ip } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "FinanceLogsList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState(ip ?? "");
  const [formData, setFormData] = useState({
    date1: ip
      ? formatDate(addDays(getDate(), -30))
      : formatDate(TimezoneProvider.time),
    date2: formatDate(TimezoneProvider.time),
    card: selCard ? selCard : null,
    page: 1,
    sort: 0,
    sortBy: 1,
    onlyMe: false,
    account: selAdmin ? selAdmin : null,
  });

  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.ip = search;
      Requests.postData("/logs/getLogs", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {!selCard &&
        !selAdmin &&
        (ConfigProvider.config.isShopSystem &&
          ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("Logs")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Search form */}
                <SearchFilter
                  placeholder={ConfigProvider.getTranslation("IpAdress.Mini")}
                  search={search}
                  setSearch={(e) => {
                    !ip && setSearch(e);
                  }}
                  disabled={ip}
                />
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 sm:mb-0">
                {!selCard && (
                  <DropdownCard
                    setSelectedItem={(val) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          card: val,
                        };
                      });
                    }}
                  />
                )}
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                <input
                  id="date1"
                  name="date1"
                  type="date"
                  value={formData.date1}
                  onChange={(e) => {
                    return setFormData({ ...formData, date1: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartDate")}
                  disabled={loading}
                  required
                />
                <input
                  id="date2"
                  name="date2"
                  type="date"
                  value={formData.date2}
                  onChange={(e) => {
                    return setFormData({ ...formData, date2: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("EndDate")}
                  disabled={loading}
                  required
                />
                {!selAdmin && (
                  <DropdownFilter align="right">
                    <ul className="mb-4">
                      <li className="py-1 px-3">
                        <label className="flex items-center">
                          <input
                            id="onlyMe"
                            name="onlyMe"
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.onlyMe}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                onlyMe: !formData.onlyMe,
                              })
                            }
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {ConfigProvider.getTranslation("OnlyMe")}
                          </span>
                        </label>
                      </li>
                    </ul>
                  </DropdownFilter>
                )}
              </div>
            </div>

            {/* Table */}
            <LogsTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
              selCard={selCard}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />
            </div>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  ) : null;
}

function LogsTable({ data, loading, formData, setFormData, selCard }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Logs")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-left">#</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Username")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Type")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Date")}
                    </div>
                  </SortByItem>
                </th>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="font-semibold text-center">
                      {ConfigProvider.getTranslation("IpAdress.Mini")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  &nbsp;
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">
                    {ConfigProvider.getTranslation("Browser")}
                  </div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="7">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="7" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              ) : (
                data.map((item) => (
                  <tr key={item._id}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      <div
                        className={`${
                          item.type === "Logs.Login"
                            ? "bg-emerald-500"
                            : "bg-rose-500"
                        } w-9 h-9 rounded-full shrink-0 my-2 mr-3`}
                      >
                        {item.type === "Logs.Login" ? (
                          <ArrowRightEndOnRectangleIcon className="w-9 h-9 text-indigo-50 p-2" />
                        ) : (
                          <ArrowRightStartOnRectangleIcon className="w-9 h-9 text-indigo-50 p-2" />
                        )}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      {!selCard ? (
                        <Link
                          to={
                            item.isCard
                              ? "/cards/card/view/" + item.aid
                              : "/accounts/account/view/" + item.shop
                          }
                          className="text-sky-500 dark:text-sky-400 font-medium text-left"
                        >
                          {item.uname}
                        </Link>
                      ) : (
                        <div className="text-sky-500 dark:text-sky-400 font-medium text-left">
                          {item.uname}
                        </div>
                      )}
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      <div
                        className={`text-center ${
                          item.type === "Logs.Login"
                            ? "text-emerald-500"
                            : "text-rose-500"
                        }`}
                      >
                        {ConfigProvider.getTranslation(item.type)}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="flex items-center justify-center w-full">
                        <CalendarIcon className="w-4 h-4 mr-1" />
                        {new Intl.DateTimeFormat("default", {
                          day: "numeric",
                          month: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          timeZone: PrefencesProvider.prefences.timezone,
                        }).format(new Date(item.date))}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="text-center">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://ipinfo.io/${item.ip}`}
                          className="font-medium text-indigo-500"
                        >
                          {item.ip}
                        </a>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      {ConfigProvider.config.isShopSystem && (
                        <ShopTooltip
                          size="lg"
                          position="right"
                          bg="dark"
                          shopName=""
                          shop={item.shop}
                          auth="2"
                        />
                      )}
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap lg:whitespace-pre-wrap w-full">
                      <div className="text-left">{item.useragent}</div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Logs;
