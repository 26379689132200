import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import Dropdown from "../../components/Dropdown";

function ConfDomain() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SiteManageDomain")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(true);

  const [domains, setDomains] = useState({});
  const [hostnames, setHostnames] = useState({});

  useEffect(() => {
    if (
      Object.keys(hostnames).length === 0 &&
      Object.keys(domains).length === 0
    ) {
      Requests.postData("/administration/getDomainsHostnamesSelect", {})
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res);
          if (res.type === "success") {
            let newDomains = {},
              newHostnames = {};
            res.data.domains.forEach((x) => (newDomains[x] = x));
            res.data.hostnames.forEach((x) => (newHostnames[x] = x));
            setDomains(newDomains);
            setHostnames(newHostnames);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line
  }, []);

  return !loading ? (
    <>
      <UpdateDomain domains={domains} hostnames={hostnames} />
      <UpdateNextDomain domains={domains} />
      <DeleteCustomDomain />
      {checkRole(ConfigProvider.config.user, "SiteClearCache") && (
        <ClearCache />
      )}
    </>
  ) : null;
}

function UpdateDomain({ domains, hostnames }) {
  const ConfigProvider = useConfig();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    newDomain: "",
    actualDomain: "",
  });
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      let requiredFieldErros = [];
      if (formData.actualDomain.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("ActualDomain"));
      }
      if (formData.newDomain.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("NewDomain"));
      }
      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
      } else {
        setLoading(true);
        setMessages([]);

        Requests.postData("/administration/updateDomain", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);

              setTimeout(() => {
                navigate(`/system/confDomain`);
              }, 1000);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <div className="grow max-w-3xl mx-auto lg:mt-20">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("SwitchToNewDomain")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <section>
            <div className="relative">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="actualDomain"
              >
                {ConfigProvider.getTranslation("ActualDomain")}
              </label>
              <Dropdown
                name="actualDomain"
                items={hostnames}
                selected={formData.actualDomain}
                setSelected={(val) => {
                  setFormData({ ...formData, actualDomain: val });
                }}
              />
            </div>
          </section>
          <div className="relative">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="newDomain"
            >
              {ConfigProvider.getTranslation("NewDomain")}
            </label>
            <Dropdown
              name="newDomain"
              items={domains}
              selected={formData.newDomain}
              setSelected={(val) => {
                setFormData({ ...formData, newDomain: val });
              }}
            />
          </div>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex self-end">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}
function UpdateNextDomain({ domains }) {
  const ConfigProvider = useConfig();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    newDomain: "",
  });
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      let requiredFieldErros = [];
      if (formData.newDomain.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("NewDomain"));
      }
      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
      } else {
        setLoading(true);
        setMessages([]);

        Requests.postData("/administration/updateNextDomain", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);

              setTimeout(() => {
                navigate(`/system/confDomain`);
              }, 1000);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <div className="grow max-w-3xl mx-auto lg:mt-20">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("CustomDomain")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>

          <div className="relative">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="newDomain"
            >
              {ConfigProvider.getTranslation("NewDomain")}
            </label>
            <Dropdown
              name="newDomain"
              items={domains}
              selected={formData.newDomain}
              setSelected={(val) => {
                setFormData({ ...formData, newDomain: val });
              }}
            />
          </div>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex self-end">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}
function DeleteCustomDomain() {
  const ConfigProvider = useConfig();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    domain: "",
  });
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      let requiredFieldErros = [];

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
      } else {
        setLoading(true);
        setMessages([]);

        Requests.postData("/administration/deleteCustomDomain", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);

              setTimeout(() => {
                navigate(`/system/confDomain`);
              }, 1000);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  return (
    <div className="grow max-w-3xl mx-auto lg:mt-20">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("DeleteCustomDomain")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>

          <div className="relative">
            <label className="block text-sm font-medium mb-1" htmlFor="domain">
              {ConfigProvider.getTranslation("Domain")}
            </label>
            <input
              id="domain"
              name="domain"
              type="text"
              value={formData.domain}
              onChange={handleChange}
              className="w-full form-input"
              placeholder={ConfigProvider.getTranslation("Domain")}
              required
              maxLength={20}
              disabled={loading}
            />
          </div>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex self-end">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

function ClearCache() {
  const ConfigProvider = useConfig();

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      let requiredFieldErros = [];

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
      } else {
        setLoading(true);
        setMessages([]);

        Requests.postData("/administration/clearCache", {})
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <div className="grow max-w-3xl mx-auto lg:mt-20">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("ClearCache")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex self-end">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Clear")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}
export default ConfDomain;
