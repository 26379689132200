import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";

import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  ArrowRightCircleIcon,
  PencilSquareIcon,
  XMarkIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import Alert from "../../components/Alert";
import Requests from "../../helpers/Requests";
import { useParams } from "react-router-dom";
import { useToastr } from "../../context/ToastrContext";
import CountryDropdown from "../../components/CountryDropdown";
import { usePrefences } from "../../context/PrefencesContext";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import CardLayout from "./helpers/CardLayout";
import Confirm from "../../components/Confirm";
import Dropdown from "../../components/Dropdown";

function EditCard() {
  return (
    <CardLayout>
      <EditCardPage />
    </CardLayout>
  );
}

function EditCardPage() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const [loading, setLoading] = useState(false);
  const [messages1, setMessages1] = useState([]);
  const [messages2, setMessages2] = useState([]);
  const [messages3, setMessages3] = useState([]);
  const [messages4, setMessages4] = useState([]);
  const [cardInfo, setCardInfo] = useState(ConfigProvider.customData);
  const [oldCardInfo, setOldCardInfo] = useState(null);
  const [editInfo, setEditInfo] = useState(false);
  const [cardDetail, setCardDetail] = useState(null);
  const [oldCardDetail, setOldCardDetail] = useState(null);
  const [editDetail, setEditDetail] = useState(false);

  const [editPassword, setEditPassword] = useState(false);
  const { _id } = useParams();
  const getItems = () => {
    if (
      !loading &&
      checkRole(ConfigProvider.config.user, "CardsViewPersonalInformation")
    ) {
      setLoading(true);
      Requests.postData("/cards/getCPI", { _id: _id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setCardDetail(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setCardInfo(ConfigProvider.customData);
    // eslint-disable-next-line
  }, [ConfigProvider.customData]);

  const updateCard = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages1([]);
      let postData = cardInfo;
      cardInfo.preLimitFactor = Number(cardInfo.preLimitFactor) || 1;
      cardInfo.liveLimitFactor = Number(cardInfo.liveLimitFactor) || 1;

      cardInfo.maxOpenBetLimitSport =
        Number(cardInfo.maxOpenBetLimitSport) || null;

      Requests.postData("/cards/updateCard", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages1([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages1([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            ConfigProvider.setRefreshCustomData(true);
            setEditInfo(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const updateCardPassword = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages2([]);
      Requests.postData("/cards/updateCardPassword", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages2([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages2([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);

            setEditPassword(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const resetCardGA = () => {
    if (!loading) {
      setLoading(true);
      setMessages4([]);
      Requests.postData("/cards/resetCardGA", { _id: _id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages4([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages4([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              ConfigProvider.setRefreshCustomData(true);
            }, 1500);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const resendVerificationEmail = () => {
    if (!loading) {
      setLoading(true);
      setMessages4([]);
      Requests.postData("/cards/resendVerificationEmail", { _id: _id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages4([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages4([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              ConfigProvider.setRefreshCustomData(true);
            }, 1500);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const updateCPI = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages3([]);
      Requests.postData("/cards/updateCPI", cardDetail)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages3([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages3([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setEditDetail(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleChangeInfo = (e) => {
    if (cardInfo && !loading) {
      const { name, value } = e.target;
      setCardInfo({ ...cardInfo, [name]: value });
    }
  };
  const handleChangeDetail = (e) => {
    if (cardDetail && !loading) {
      const { name, value } = e.target;
      if (name === "phone") {
        const regex = /^[+\d- ]*$/;
        if (regex.test(value) && value.length <= 15) {
          setCardDetail({ ...cardDetail, [name]: value.split(" ").join("") });
        }
      } else {
        setCardDetail({ ...cardDetail, [name]: value });
      }
    }
  };

  const [formData, setFormData] = useState({
    _id: _id,
    password: "",
    password2: "",
  });
  const handleChangePassword = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const PrefencesProvider = usePrefences();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmVerification, setShowConfirmVerification] = useState(false);
  const stats = {
    0: ConfigProvider.getTranslation("Passive"),
    1: ConfigProvider.getTranslation("Active"),
    3: ConfigProvider.getTranslation("SelfExcluded"),
  };

  const [allCategories, setAllCategories] = useState({});
  useEffect(() => {
    Requests.postData("/cards/getCategoriesSelect", {})
      .then((response) => {
        const res = response.data;
        res.type === "success" && setAllCategories(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <form
        className="w-full border-b border-slate-200 dark:border-slate-700"
        onSubmit={updateCard}
        autoComplete="off"
      >
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("Register.AccountInformation")}
            {!editInfo && checkRole(ConfigProvider.config.user, "CardEdit") && (
              <button
                type="button"
                onClick={() => {
                  if (!editInfo) {
                    setEditInfo(true);
                    setOldCardInfo(cardInfo);
                  }
                }}
                className="ml-2 bg-indigo-500 rounded-lg px-2 py-1 text-sm flex"
              >
                <PencilSquareIcon className="w-5 h-5 md:mr-2" />
                <span className="hidden md:inline-block">
                  {ConfigProvider.getTranslation("Edit")}
                </span>
              </button>
            )}
          </h2>
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages1} setMessages={setMessages1} />
          </div>

          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="uname"
                >
                  {ConfigProvider.getTranslation("Username")}
                </label>
                {editInfo ? (
                  <input
                    id="uname"
                    name="uname"
                    type="text"
                    value={cardInfo && cardInfo.uname}
                    onChange={handleChangeInfo}
                    className="w-full form-input"
                    placeholder={ConfigProvider.getTranslation("Username")}
                    required
                    maxLength={15}
                    disabled={!cardInfo | loading}
                  />
                ) : (
                  <label className="block text-sm w-full">
                    {cardInfo && cardInfo.uname}
                  </label>
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="currency"
                >
                  {ConfigProvider.getTranslation("Currency")}
                </label>
                {editInfo ? (
                  <input
                    id="currency"
                    name="currency"
                    type="text"
                    value={cardInfo && cardInfo.currency}
                    className="w-full form-input"
                    placeholder={ConfigProvider.getTranslation("Currency")}
                    required
                    maxLength={15}
                    disabled={true}
                  />
                ) : (
                  <label className="block text-sm w-full">
                    {cardInfo && cardInfo.currency}
                  </label>
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  {ConfigProvider.getTranslation("Name")}
                </label>
                {editInfo ? (
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={cardInfo && cardInfo.name}
                    onChange={handleChangeInfo}
                    className="w-full form-input"
                    placeholder={ConfigProvider.getTranslation("Name")}
                    maxLength={50}
                    disabled={!cardInfo | loading}
                  />
                ) : (
                  <label className="block text-sm w-full">
                    {cardInfo && cardInfo.name}
                  </label>
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="sname"
                >
                  {ConfigProvider.getTranslation("Surname")}
                </label>
                {editInfo ? (
                  <input
                    id="sname"
                    name="sname"
                    type="text"
                    value={cardInfo && cardInfo.sname}
                    onChange={handleChangeInfo}
                    className="w-full form-input"
                    placeholder={ConfigProvider.getTranslation("Surname")}
                    maxLength={50}
                    disabled={!cardInfo | loading}
                  />
                ) : (
                  <label className="block text-sm w-full">
                    {cardInfo && cardInfo.sname}
                  </label>
                )}
              </div>
              {checkRole(
                ConfigProvider.config.user,
                "CardsViewAndUpdateMail"
              ) && (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="email"
                  >
                    {ConfigProvider.getTranslation("E-mail")}
                  </label>
                  {editInfo ? (
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={cardInfo && (cardInfo.email ?? "")}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("E-mail")}
                      disabled={!cardInfo | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardInfo && cardInfo.email}
                    </label>
                  )}
                </div>
              )}
              {checkRole(
                ConfigProvider.config.user,
                "CardsViewAndUpdateIdentity"
              ) && (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="identity"
                  >
                    {ConfigProvider.getTranslation("Identity")}
                  </label>

                  {editInfo ? (
                    <input
                      id="identity"
                      name="identity"
                      type="text"
                      value={cardInfo && (cardInfo.identity ?? "")}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("Identity")}
                      maxLength={20}
                      disabled={!cardInfo | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardInfo && cardInfo.identity}
                    </label>
                  )}
                </div>
              )}

              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="category"
                >
                  {ConfigProvider.getTranslation("Category")}
                </label>
                <div className="my-2">
                  {cardInfo && Object.keys(allCategories).length > 0 && (
                    <Dropdown
                      name="category"
                      items={allCategories}
                      selected={cardInfo.category}
                      setSelected={(val) =>
                        editInfo &&
                        setCardInfo({
                          ...cardInfo,
                          category: val,
                        })
                      }
                      disabled={!cardInfo | loading}
                    />
                  )}
                </div>
                {cardInfo && cardInfo.stat === 3 && cardInfo.lockFinishDate && (
                  <div className="my-2">
                    {`${ConfigProvider.getTranslation("ReActivationDate")}: ${
                      cardInfo.lockFinishDate
                    }`}
                  </div>
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="stat"
                >
                  {ConfigProvider.getTranslation("Status")}
                </label>
                <div className="my-2">
                  {cardInfo && (
                    <Dropdown
                      name="stat"
                      items={stats}
                      selected={cardInfo.stat.toString()}
                      setSelected={(val) =>
                        editInfo &&
                        setCardInfo({
                          ...cardInfo,
                          stat: parseInt(val),
                        })
                      }
                      disabled={!cardInfo | loading}
                    />
                  )}
                </div>
                {cardInfo && cardInfo.stat === 3 && cardInfo.lockFinishDate && (
                  <div className="my-2">
                    {`${ConfigProvider.getTranslation("ReActivationDate")}: ${
                      cardInfo.lockFinishDate
                    }`}
                  </div>
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="allowDeposit"
                >
                  {ConfigProvider.getTranslation("AllowDeposit")}
                </label>
                <div className="m-3 w-24">
                  {cardInfo && (
                    <div className="flex items-center">
                      <div className="form-switch">
                        <input
                          id="allowDeposit"
                          name="allowDeposit"
                          type="checkbox"
                          className="sr-only"
                          checked={cardInfo && cardInfo.allowDeposit}
                          onChange={() =>
                            editInfo &&
                            setCardInfo({
                              ...cardInfo,
                              allowDeposit: !cardInfo.allowDeposit,
                            })
                          }
                          disabled={!cardInfo | loading}
                          readOnly
                        />
                        <label
                          className="bg-slate-400 dark:bg-slate-700"
                          htmlFor="allowDeposit"
                        >
                          <span
                            className="bg-white shadow-sm"
                            aria-hidden="true"
                          ></span>
                        </label>
                      </div>
                      <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                        {cardInfo && cardInfo.allowDeposit
                          ? ConfigProvider.getTranslation("Active")
                          : ConfigProvider.getTranslation("Passive")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="allowWithdrawal"
                >
                  {ConfigProvider.getTranslation("AllowWithdrawal")}
                </label>
                <div className="m-3 w-24">
                  {cardInfo && (
                    <div className="flex items-center">
                      <div className="form-switch">
                        <input
                          id="allowWithdrawal"
                          name="allowWithdrawal"
                          type="checkbox"
                          className="sr-only"
                          checked={cardInfo && cardInfo.allowWithdrawal}
                          onChange={() =>
                            editInfo &&
                            setCardInfo({
                              ...cardInfo,
                              allowWithdrawal: !cardInfo.allowWithdrawal,
                            })
                          }
                          disabled={!cardInfo | loading}
                          readOnly
                        />
                        <label
                          className="bg-slate-400 dark:bg-slate-700"
                          htmlFor="allowWithdrawal"
                        >
                          <span
                            className="bg-white shadow-sm"
                            aria-hidden="true"
                          ></span>
                        </label>
                      </div>
                      <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                        {cardInfo && cardInfo.allowWithdrawal
                          ? ConfigProvider.getTranslation("Active")
                          : ConfigProvider.getTranslation("Passive")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {checkRole(
                ConfigProvider.config.user,
                "CardsViewAndUpdateMail"
              ) && (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="validatedEmail"
                  >
                    {ConfigProvider.getTranslation("ValidatedEmail")}
                  </label>
                  <div className="m-3 w-24">
                    {cardInfo && (
                      <div className="flex items-center">
                        <div className="form-switch">
                          <input
                            id="validatedEmail"
                            name="validatedEmail"
                            type="checkbox"
                            className="sr-only"
                            checked={cardInfo && cardInfo.validatedEmail}
                            onChange={() =>
                              editInfo &&
                              setCardInfo({
                                ...cardInfo,
                                validatedEmail: !cardInfo.validatedEmail,
                              })
                            }
                            disabled={!cardInfo | loading}
                            readOnly
                          />
                          <label
                            className="bg-slate-400 dark:bg-slate-700"
                            htmlFor="validatedEmail"
                          >
                            <span
                              className="bg-white shadow-sm"
                              aria-hidden="true"
                            ></span>
                          </label>
                        </div>
                        <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                          {cardInfo && cardInfo.validatedEmail
                            ? ConfigProvider.getTranslation("Active")
                            : ConfigProvider.getTranslation("Passive")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="relative">
                <label className="block text-sm font-medium mb-1" htmlFor="ga">
                  {ConfigProvider.getTranslation("2FA")}
                </label>
                <div className="m-3 w-24">
                  {cardInfo && (
                    <div className="flex items-center">
                      <div className="form-switch">
                        <input
                          id="ga"
                          name="ga"
                          type="checkbox"
                          className="sr-only"
                          checked={cardInfo && cardInfo.gaActive}
                          readOnly
                        />
                        <label
                          className="bg-slate-400 dark:bg-slate-700"
                          htmlFor="ga"
                        >
                          <span
                            className="bg-white shadow-sm"
                            aria-hidden="true"
                          ></span>
                        </label>
                      </div>
                      <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                        {cardInfo && cardInfo.gaActive
                          ? ConfigProvider.getTranslation("Active")
                          : ConfigProvider.getTranslation("Passive")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {!ConfigProvider.config.onlyCasino && (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="isPlaceBet"
                  >
                    {ConfigProvider.getTranslation("IsPlaceBet")}
                  </label>
                  <div className="m-3 w-24">
                    {cardInfo && (
                      <div className="flex items-center">
                        <div className="form-switch">
                          <input
                            id="isPlaceBet"
                            name="isPlaceBet"
                            type="checkbox"
                            className="sr-only"
                            checked={cardInfo && cardInfo.isPlaceBet}
                            onChange={() =>
                              editInfo &&
                              setCardInfo({
                                ...cardInfo,
                                isPlaceBet: !cardInfo.isPlaceBet,
                              })
                            }
                            disabled={!cardInfo | loading}
                            readOnly
                          />
                          <label
                            className="bg-slate-400 dark:bg-slate-700"
                            htmlFor="isPlaceBet"
                          >
                            <span
                              className="bg-white shadow-sm"
                              aria-hidden="true"
                            ></span>
                          </label>
                        </div>
                        <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                          {cardInfo && cardInfo.isPlaceBet
                            ? ConfigProvider.getTranslation("Active")
                            : ConfigProvider.getTranslation("Passive")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!ConfigProvider.config.onlySports && (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="casinoActive"
                  >
                    {ConfigProvider.getTranslation("Casino")}
                  </label>
                  <div className="m-3 w-24">
                    {cardInfo && (
                      <div className="flex items-center">
                        <div className="form-switch">
                          <input
                            id="casinoActive"
                            name="casinoActive"
                            type="checkbox"
                            className="sr-only"
                            checked={cardInfo && cardInfo.casinoActive}
                            onChange={() =>
                              editInfo &&
                              setCardInfo({
                                ...cardInfo,
                                casinoActive: !cardInfo.casinoActive,
                              })
                            }
                            disabled={!cardInfo | loading}
                            readOnly
                          />
                          <label
                            className="bg-slate-400 dark:bg-slate-700"
                            htmlFor="casinoActive"
                          >
                            <span
                              className="bg-white shadow-sm"
                              aria-hidden="true"
                            ></span>
                          </label>
                        </div>
                        <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                          {cardInfo && cardInfo.casinoActive
                            ? ConfigProvider.getTranslation("Active")
                            : ConfigProvider.getTranslation("Passive")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="relative">
                <label className="block text-sm font-medium mb-1">
                  {ConfigProvider.getTranslation("Btag")}
                </label>
                <div className="m-3 w-24">
                  {cardInfo && (
                    <div className="flex items-center">{cardInfo.btag}</div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        {!ConfigProvider.config.onlyCasino && (
          <div className="p-6 space-y-6">
            <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
              {ConfigProvider.getTranslation("SportsLimits")}
            </h2>
            <section>
              <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="limMinAmount"
                  >
                    {`${ConfigProvider.getTranslation(
                      "Sports"
                    )} - ${ConfigProvider.getTranslation("MinAmount")}`}
                  </label>

                  {editInfo ? (
                    <input
                      id="limMinAmount"
                      name="limMinAmount"
                      type="number"
                      value={cardInfo && (cardInfo.limMinAmount ?? "")}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("MinAmount")}
                      maxLength={15}
                      disabled={!cardInfo | loading}
                      required
                      step={1}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardInfo && cardInfo.limMinAmount > 0
                        ? cardInfo.limMinAmount
                        : ConfigProvider.getTranslation("Undefined")}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="limMaxAmount"
                  >
                    {`${ConfigProvider.getTranslation(
                      "Sports"
                    )} - ${ConfigProvider.getTranslation("MaxAmount")}`}
                  </label>

                  {editInfo ? (
                    <input
                      id="limMaxAmount"
                      name="limMaxAmount"
                      type="text"
                      value={cardInfo && (cardInfo.limMaxAmount ?? "")}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("MaxAmount")}
                      maxLength={15}
                      disabled={!cardInfo | loading}
                      required
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardInfo && cardInfo.limMaxAmount > 0
                        ? cardInfo.limMaxAmount
                        : ConfigProvider.getTranslation("Undefined")}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="preLimitFactor"
                  >
                    {`${ConfigProvider.getTranslation(
                      "PreGame"
                    )} - ${ConfigProvider.getTranslation("LimitFactor")}`}
                  </label>

                  {editInfo ? (
                    <input
                      id="preLimitFactor"
                      name="preLimitFactor"
                      type="text"
                      value={cardInfo && (cardInfo.preLimitFactor ?? "")}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("MinAmount")}
                      maxLength={15}
                      disabled={!cardInfo | loading}
                      required
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardInfo &&
                        parseFloat(cardInfo.preLimitFactor).toFixed(2)}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="liveLimitFactor"
                  >
                    {`${ConfigProvider.getTranslation(
                      "Live"
                    )} - ${ConfigProvider.getTranslation("LimitFactor")}`}
                  </label>

                  {editInfo ? (
                    <input
                      id="liveLimitFactor"
                      name="liveLimitFactor"
                      type="text"
                      value={cardInfo && (cardInfo.liveLimitFactor ?? "")}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("MinAmount")}
                      maxLength={15}
                      disabled={!cardInfo | loading}
                      required
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardInfo &&
                        parseFloat(cardInfo.liveLimitFactor).toFixed(2)}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="maxOpenBetLimitSport"
                  >
                    {`${ConfigProvider.getTranslation("MaxOpenBetLimit")}`}
                  </label>
                  {editInfo ? (
                    <input
                      id="maxOpenBetLimitSport"
                      name="maxOpenBetLimitSport"
                      type="text"
                      value={cardInfo && cardInfo.maxOpenBetLimitSport}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation(
                        "MaxOpenBetLimit"
                      )}
                      maxLength={15}
                      disabled={!cardInfo | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardInfo && cardInfo.maxOpenBetLimitSport
                        ? cardInfo.maxOpenBetLimitSport
                        : ConfigProvider.getTranslation("Undefined")}
                    </label>
                  )}
                </div>
              </div>
            </section>
          </div>
        )}
        {/* Panel footer */}
        {editInfo && (
          <footer>
            <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
              <div className="flex self-end">
                <button
                  type="button"
                  disabled={!cardInfo | loading}
                  className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
                  onClick={() => {
                    setEditInfo(false);
                    setCardInfo(oldCardInfo);
                  }}
                >
                  <span>
                    <XMarkIcon className="w-4 h-4 mr-2" />
                  </span>
                  <span className="mr-2 uppercase">
                    {ConfigProvider.getTranslation("Cancel")}
                  </span>
                </button>
                <button
                  type="submit"
                  disabled={!cardInfo | loading}
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                >
                  <span className="mr-2 uppercase">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-4 h-4 mb-1" />
                    </span>
                  ) : (
                    <span>
                      <ArrowRightCircleIcon className="w-4 h-4" />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </footer>
        )}
      </form>
      {checkRole(ConfigProvider.config.user, "CardChangePassword") && (
        <form
          className="w-full border-b border-slate-200 dark:border-slate-700"
          onSubmit={updateCardPassword}
          autoComplete="off"
        >
          <div className="p-6 space-y-6">
            <section>
              <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
                {ConfigProvider.getTranslation("Password")}
              </h2>

              <div className="text-sm">
                {ConfigProvider.getTranslation(
                  "PermanentPasswordIfYouDontWantToUseTemporaryLoginCodes"
                )}
              </div>
              <div className="w-full mt-2 mb-4">
                <Alert messages={messages2} setMessages={setMessages2} />
              </div>
              <div className="mt-5">
                {!editPassword && (
                  <button
                    type="button"
                    className="btn border-slate-200 dark:border-slate-700 shadow-sm text-indigo-500"
                    onClick={() => {
                      setEditPassword(true);
                      setFormData({ ...formData, password: "", password2: "" });
                    }}
                  >
                    {ConfigProvider.getTranslation("ChangePassword")}
                  </button>
                )}
                {editPassword && (
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        {ConfigProvider.getTranslation("Password.New")}
                      </label>
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="w-full form-input"
                        placeholder="*******"
                        value={formData.password}
                        onChange={handleChangePassword}
                        required
                        maxLength={15}
                        disabled={loading}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-color-3" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-color-3" />
                        )}
                      </div>
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password2"
                      >
                        {ConfigProvider.getTranslation("Password.NewAgain")}
                      </label>
                      <input
                        id="password2"
                        name="password2"
                        type={showPassword ? "text" : "password"}
                        className="w-full form-input"
                        placeholder="*******"
                        value={formData.password2}
                        onChange={handleChangePassword}
                        required
                        maxLength={15}
                        disabled={loading}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-color-3" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-color-3" />
                        )}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <ul className="ml-10 my-2 list-disc">
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.MinMaxLength"
                          )}
                        </li>
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.RequiredUppcaseandLowercaseAndNumber"
                          )}
                        </li>
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.CaseSensetive"
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
          {/* Panel footer */}
          {editPassword && (
            <footer>
              <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                <div className="flex self-end">
                  <button
                    type="button"
                    disabled={loading}
                    className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
                    onClick={() => {
                      setEditPassword(false);
                    }}
                  >
                    <span>
                      <XMarkIcon className="w-4 h-4 mr-2" />
                    </span>
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Cancel")}
                    </span>
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-4 h-4 mb-1" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-4 h-4" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </footer>
          )}
        </form>
      )}

      {checkRole(ConfigProvider.config.user, "CardEdit") &&
        cardInfo &&
        cardInfo.gaActive && (
          <div className="w-full border-b border-slate-200 dark:border-slate-700">
            <div className="p-6 space-y-6">
              <section>
                <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
                  {ConfigProvider.getTranslation("2FA")}
                </h2>

                <div className="text-sm">
                  {ConfigProvider.getTranslation(
                    "ResetTheUser2FactorAuthentication"
                  )}
                </div>
                <div className="w-full mt-2 mb-4">
                  <Alert messages={messages4} setMessages={setMessages4} />
                </div>
                <div className="mt-5">
                  <button
                    type="button"
                    className="btn border-slate-200 dark:border-slate-700 shadow-sm text-indigo-500"
                    onClick={() => {
                      setShowConfirm(true);
                    }}
                  >
                    {ConfigProvider.getTranslation("Reset2FA")}
                  </button>
                </div>
              </section>
            </div>
          </div>
        )}

      {checkRole(ConfigProvider.config.user, "CardEdit") &&
      cardInfo &&
      cardInfo.email.length &&
      !cardInfo.validatedEmail > 0 ? (
        <div className="w-full border-b border-slate-200 dark:border-slate-700">
          <div className="p-6 space-y-6">
            <section>
              <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
                {ConfigProvider.getTranslation("EmailVerification")}
              </h2>

              <div className="text-sm">
                {ConfigProvider.getTranslation("ResendVerificationEmail")}
              </div>
              <div className="w-full mt-2 mb-4">
                <Alert messages={messages4} setMessages={setMessages4} />
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  className="btn border-slate-200 dark:border-slate-700 shadow-sm text-indigo-500"
                  onClick={() => {
                    setShowConfirmVerification(true);
                  }}
                >
                  {ConfigProvider.getTranslation("SendEmail")}
                </button>
              </div>
            </section>
          </div>
        </div>
      ) : null}

      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>
                {ConfigProvider.getTranslation(
                  "ResetTheUser2FactorAuthentication"
                )}
              </p>
            </>
          }
          confirm={() => {
            resetCardGA();
            setShowConfirm(false);
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
      {showConfirmVerification && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{ConfigProvider.getTranslation("ResendVerificationEmail")}</p>
            </>
          }
          confirm={() => {
            resendVerificationEmail();
            setShowConfirmVerification(false);
          }}
          callback={() => {
            setShowConfirmVerification(false);
          }}
        />
      )}

      {checkRole(
        ConfigProvider.config.user,
        "CardsViewPersonalInformation"
      ) && (
        <form className="w-full" onSubmit={updateCPI}>
          {/* Panel body */}
          <div className="p-6 space-y-6">
            <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
              {ConfigProvider.getTranslation("Register.PersonalInformation")}
              {!editDetail &&
                checkRole(
                  ConfigProvider.config.user,
                  "CardsEditPersonalInformation"
                ) && (
                  <button
                    type="button"
                    onClick={() => {
                      if (!editDetail) {
                        setEditDetail(true);
                        setOldCardDetail(cardDetail);
                      }
                    }}
                    className="ml-2 bg-indigo-500 rounded-lg px-2 py-1 text-sm flex"
                  >
                    <PencilSquareIcon className="w-5 h-5 md:mr-2" />
                    <span className="hidden md:inline-block">
                      {ConfigProvider.getTranslation("Edit")}
                    </span>
                  </button>
                )}
            </h2>
            <div className="w-full mt-2 mb-4">
              <Alert messages={messages3} setMessages={setMessages3} />
            </div>

            <section>
              <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="gender"
                  >
                    {ConfigProvider.getTranslation("Gender")}
                  </label>
                  {editDetail ? (
                    <div className="flex flex-wrap items-center -mx-3">
                      <div className="m-3">
                        {/* Start */}
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="radio-buttons"
                            className="form-radio"
                            checked={cardDetail.gender === "M"}
                            onChange={() => {
                              setCardDetail({ ...cardDetail, gender: "M" });
                            }}
                            disabled={!cardDetail | loading}
                          />
                          <span className="text-sm ml-2">
                            {ConfigProvider.getTranslation("Male")}
                          </span>
                        </label>
                        {/* End */}
                      </div>

                      <div className="m-3">
                        {/* Start */}
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="radio-buttons"
                            className="form-radio"
                            checked={cardDetail.gender === "W"}
                            onChange={() => {
                              setCardDetail({ ...cardDetail, gender: "W" });
                            }}
                            disabled={!cardDetail | loading}
                          />
                          <span className="text-sm ml-2">
                            {ConfigProvider.getTranslation("Female")}
                          </span>
                        </label>
                        {/* End */}
                      </div>
                    </div>
                  ) : (
                    <label className="block text-sm w-full">
                      {cardDetail &&
                        (cardDetail.gender === "M"
                          ? ConfigProvider.getTranslation("Male")
                          : (cardDetail.gender = "F"
                              ? ConfigProvider.getTranslation("Female")
                              : ConfigProvider.getTranslation(
                                  "Dropdown.SelectOne"
                                )))}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="country"
                  >
                    {ConfigProvider.getTranslation("Country")}
                  </label>
                  {editDetail ? (
                    <CountryDropdown
                      name="country"
                      selected={cardDetail ? cardDetail.country ?? "" : ""}
                      setSelected={(val) => {
                        setCardDetail({ ...cardDetail, country: val });
                      }}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardDetail &&
                        ConfigProvider.config.countires[cardDetail.country]}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="nationality"
                  >
                    {ConfigProvider.getTranslation("Nationality")}
                  </label>
                  {editDetail ? (
                    <CountryDropdown
                      name="nationality"
                      selected={cardDetail ? cardDetail.nationality ?? "" : ""}
                      setSelected={(val) => {
                        setCardDetail({ ...cardDetail, nationality: val });
                      }}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardDetail &&
                        ConfigProvider.config.countires[cardDetail.nationality]}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="countryOfBirth"
                  >
                    {ConfigProvider.getTranslation("CountryOfBirth")}
                  </label>
                  {editDetail ? (
                    <CountryDropdown
                      name="countryOfBirth"
                      selected={
                        cardDetail ? cardDetail.countryOfBirth ?? "" : ""
                      }
                      setSelected={(val) => {
                        setCardDetail({ ...cardDetail, countryOfBirth: val });
                      }}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardDetail &&
                        ConfigProvider.config.countires[
                          cardDetail.countryOfBirth
                        ]}
                    </label>
                  )}
                </div>

                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="dateOfBirth"
                  >
                    {ConfigProvider.getTranslation("DateOfBirth")}
                  </label>
                  {editDetail ? (
                    <input
                      id="dateOfBirth"
                      name="dateOfBirth"
                      type="date"
                      value={cardDetail && (cardDetail.dateOfBirth ?? "")}
                      onChange={handleChangeDetail}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("DateOfBirth")}
                      maxLength={15}
                      disabled={!cardDetail | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardDetail &&
                        cardDetail.dateOfBirth &&
                        new Intl.DateTimeFormat("default", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          timeZone: PrefencesProvider.prefences.timezone,
                        }).format(new Date(cardDetail.dateOfBirth))}
                    </label>
                  )}
                </div>

                {checkRole(
                  ConfigProvider.config.user,
                  "CardsViewAndUpdatePhone"
                ) && (
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="phone"
                    >
                      {ConfigProvider.getTranslation("Phone")}
                    </label>
                    {editDetail ? (
                      <input
                        id="phone"
                        name="phone"
                        type="text"
                        value={cardDetail && (cardDetail.phone ?? "")}
                        onChange={handleChangeDetail}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation("Phone")}
                        maxLength={20}
                        disabled={!cardDetail | loading}
                        pattern="^[0-9]+$"
                      />
                    ) : (
                      <label className="block text-sm w-full">
                        {cardDetail && cardDetail.phone}
                      </label>
                    )}
                  </div>
                )}
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="city"
                  >
                    {ConfigProvider.getTranslation("City")}
                  </label>
                  {editDetail ? (
                    <input
                      id="city"
                      name="city"
                      type="text"
                      value={cardDetail && (cardDetail.city ?? "")}
                      onChange={handleChangeDetail}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("City")}
                      maxLength={20}
                      disabled={!cardDetail | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardDetail && cardDetail.city}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="postalCode"
                  >
                    {ConfigProvider.getTranslation("PostalCode")}
                  </label>
                  {editDetail ? (
                    <input
                      id="postalCode"
                      name="postalCode"
                      type="text"
                      value={cardDetail && (cardDetail.postalCode ?? "")}
                      onChange={handleChangeDetail}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("PostalCode")}
                      maxLength={10}
                      disabled={!cardDetail | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardDetail && cardDetail.postalCode}
                    </label>
                  )}
                </div>
                <div className="relative col-span-2">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="address"
                  >
                    {ConfigProvider.getTranslation("Address")}
                  </label>
                  {editDetail ? (
                    <textarea
                      id="address"
                      name="address"
                      type="text"
                      value={cardDetail && (cardDetail.address ?? "")}
                      onChange={handleChangeDetail}
                      className="w-full form-input min-h-[80px]"
                      placeholder={ConfigProvider.getTranslation("Address")}
                      maxLength={50}
                      disabled={!cardDetail | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {cardDetail && cardDetail.address}
                    </label>
                  )}
                </div>
              </div>
            </section>
          </div>
          {/* Panel footer */}
          {editDetail && (
            <footer>
              <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                <div className="flex self-end">
                  <button
                    type="button"
                    disabled={!cardInfo | loading}
                    className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
                    onClick={() => {
                      setEditDetail(false);
                      setCardDetail(oldCardDetail);
                    }}
                  >
                    <span>
                      <XMarkIcon className="w-4 h-4 mr-2" />
                    </span>
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Cancel")}
                    </span>
                  </button>
                  <button
                    type="submit"
                    disabled={!cardInfo | loading}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-4 h-4 mb-1" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-4 h-4" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </footer>
          )}
        </form>
      )}
    </>
  );
}

export default EditCard;
