import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { useNavigate } from "react-router-dom";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import DropdownFilter from "../../components/DropdownFilter";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useToastr } from "../../context/ToastrContext";
import Dropdown from "../../components/Dropdown";

function CasinoGamePrefers() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkRole(ConfigProvider.config.user, "CasinoManageCasinoGamePrefers")
    ) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    sort: 0,
    sortBy: 0,
    rootProvider: "-1",
    provider: ConfigProvider.ObjectIdEmpty,
    category: ConfigProvider.ObjectIdEmpty,
    casinoPage: ConfigProvider.ObjectIdEmpty,
    buyFreespin: "-1",
    game: "-1",
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/casino/getCasinoGamePrefers", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  const [filterData, setFilterData] = useState(null);
  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = () => {
    Requests.postData("/casino/getCasinoGamePrefersFilters", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setFilterData(res.data);
        }
      })
      .catch(() => {});
  };
  const allStat = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    1: ConfigProvider.getTranslation("Active"),
    0: ConfigProvider.getTranslation("Passive"),
  };
  const gameFilter = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    0: ConfigProvider.getTranslation("Unmatched"),
    1: ConfigProvider.getTranslation("Matched"),
  };
  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}

      {messages.length > 0 && (
        <div className="mt-2 mb-4">
          <Alert messages={messages} setMessages={setMessages} />
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
      >
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("CasinoGamePrefers")} ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Search form */}
            <SearchFilter
              placeholder={ConfigProvider.getTranslation("Search")}
              search={search}
              setSearch={setSearch}
            />
            <DropdownFilter align="right">
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("BuyFreespin")}
              </div>
              <ul className="mb-4">
                {Object.keys(allStat).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.buyFreespin === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            buyFreespin: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allStat[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Game")}
              </div>
              <ul className="mb-4">
                {Object.keys(gameFilter).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.game === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            game: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {gameFilter[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </DropdownFilter>

            <button
              type="submit"
              disabled={loading}
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              {loading ? (
                <span>
                  <LoadingSpinner className="w-4 h-4 mb-1" />
                </span>
              ) : (
                <span>
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                </span>
              )}
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 space-y-2 lg:grow lg:mb-0 lg:flex lg:space-x-2 lg:space-y-0">
            {filterData && (
              <div className="relative grow">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="rootProvider"
                >
                  {ConfigProvider.getTranslation("RootProvider")}
                </label>
                <Dropdown
                  name="rootProvider"
                  items={filterData.rootProviders}
                  topItems={{
                    "-1": ConfigProvider.getTranslation("Filter.All"),
                  }}
                  selected={formData.rootProvider}
                  setSelected={(val) => {
                    setFormData({ ...formData, rootProvider: val });
                  }}
                />
              </div>
            )}
            {filterData && (
              <div className="relative grow">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="provider"
                >
                  {ConfigProvider.getTranslation("Provider")}
                </label>
                <Dropdown
                  name="provider"
                  items={filterData.providers}
                  topItems={{
                    [ConfigProvider.ObjectIdEmpty]:
                      ConfigProvider.getTranslation("Filter.All"),
                  }}
                  selected={formData.provider}
                  setSelected={(val) => {
                    setFormData({ ...formData, provider: val });
                  }}
                />
              </div>
            )}

            {filterData && (
              <div className="relative grow">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="casinoPage"
                >
                  {ConfigProvider.getTranslation("Page")}
                </label>
                <Dropdown
                  name="casinoPage"
                  items={filterData.casinoPages}
                  topItems={{
                    [ConfigProvider.ObjectIdEmpty]:
                      ConfigProvider.getTranslation("Filter.All"),
                    [ConfigProvider.ObjectIdUndefined]:
                      ConfigProvider.getTranslation("Undefined"),
                  }}
                  selected={formData.casinoPage}
                  setSelected={(val) => {
                    setFormData({ ...formData, casinoPage: val });
                  }}
                />
              </div>
            )}
            {filterData && (
              <div className="relative grow">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="category"
                >
                  {ConfigProvider.getTranslation("Category")}
                </label>
                <Dropdown
                  name="category"
                  items={filterData.casinoCategories}
                  topItems={{
                    [ConfigProvider.ObjectIdEmpty]:
                      ConfigProvider.getTranslation("Filter.All"),
                    [ConfigProvider.ObjectIdUndefined]:
                      ConfigProvider.getTranslation("Undefined"),
                  }}
                  selected={formData.category}
                  setSelected={(val) => {
                    setFormData({ ...formData, category: val });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </form>

      {/* Table */}
      <CasinoGamePrefersTable
        data={data}
        setData={setData}
        loading={loading}
        formData={formData}
        setLoading={setLoading}
        getItems={getItems}
        filterData={filterData}
        search={search}
      />

      {/* Pagination */}
      <div className="mt-8">
        <PaginationClassic
          page={formData.page}
          setPage={(val) => {
            setFormData((prev) => {
              return { ...prev, page: val };
            });
          }}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}
function CasinoGamePrefersTable({
  data,
  setData,
  loading,
  formData,
  setLoading,
  filterData,
  getItems,
  search,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [allItemRootProvider, setAllItemRootProvider] = useState("");

  const setCasinoGamePreferAggregatorAll = () => {
    if (allItemRootProvider != null) {
      if (!loading) {
        setLoading(true);
        const postData = formData;
        postData.search = search;
        postData.p = allItemRootProvider;
        Requests.postData("/casino/setCasinoGamePreferAggregatorAll", postData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res) &&
                ToastrContext.showToast({
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                });
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              ToastrContext.showToast({
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation(
                      "Forms.SuccessMessageMessage"
                    ),
              });
              setAllItemRootProvider(null);
              getItems();
            }
            setLoading(false);
          })
          .catch(() => {
            ToastrContext.showToast({
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
            setLoading(false);
          });
      }
    }
  };

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("CasinoGamePrefers")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Icon")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Name")}
                  </div>
                </th>

                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("RootProvider")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Provider")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("BuyFreespin")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Category")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Page")}
                  </div>
                </th>
              </tr>
            </thead>

            {!loading && data && data.length === 0 && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="7">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="7" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data.map((item) => (
                <CasinoGamePrefersTableItem
                  key={item._id}
                  item={item}
                  loading={loading}
                  confirmLoading={confirmLoading}
                  setConfirmLoading={setConfirmLoading}
                  filterData={filterData}
                  setData={setData}
                />
              ))}
            <tfoot className="text-sm dark:bg-slate-900/70">
              <tr>
                <td colSpan="4">&nbsp;</td>
                <td colSpan="3">
                  {filterData && (
                    <>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setCasinoGamePreferAggregatorAll();
                        }}
                        className="flex gap-2 p-3 items-center justify-center"
                      >
                        <div className="flex flex-col items-end justify-end">
                          <span>
                            {ConfigProvider.getTranslation("UpdateAll")}
                          </span>
                          <span className="text-xs">
                            ({ConfigProvider.getTranslation("RootProvider")})
                          </span>
                        </div>
                        <div className="grow">
                          <Dropdown
                            name="rootProvider"
                            items={filterData.rootProviders}
                            topItems={{
                              [ConfigProvider.ObjectIdEmpty]:
                                ConfigProvider.getTranslation("None"),
                            }}
                            selected={allItemRootProvider}
                            setSelected={(val) => {
                              setAllItemRootProvider(val);
                            }}
                            align="top"
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                          >
                            <span className="mr-2 uppercase">
                              {ConfigProvider.getTranslation("Button.Send")}
                            </span>
                            {loading ? (
                              <span>
                                <LoadingSpinner className="w-4 h-4 mb-1" />
                              </span>
                            ) : (
                              <span>
                                <ArrowRightCircleIcon className="w-4 h-4" />
                              </span>
                            )}
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
function CasinoGamePrefersTableItem({
  item,
  loading,
  confirmLoading,
  setConfirmLoading,
  filterData,
  setData,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const setCasinoGamePreferAggregator = (item, val) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/casino/setCasinoGamePreferAggregator", {
        _id: item._id,
        aggregator: val,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;
  const filterProviders = (aggregators) => {
    var send = {};
    Object.keys(filterData.rootProviders)
      .filter((x) => aggregators.hasOwnProperty(x))
      .forEach((x) => (send[x] = filterData.rootProviders[x]));
    return send;
  };
  return (
    <tbody className="text-sm">
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center inline-block">
            <img
              srcSet={
                item.imageUrl.startsWith("casino/")
                  ? `${cdnEndpoint}/upload/img/${item.imageUrl}`
                  : item.imageUrl
              }
              alt="No IMG"
              className="min-w-[120px]"
            />
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left flex flex-col">
            <div>
              <span className="text-sky-500 dark:text-sky-400 font-semibold">
                {item.displayName}
              </span>
            </div>
          </div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">
            {filterData && (
              <Dropdown
                name="rootProvider"
                items={filterProviders(item.aggregators)}
                topItems={{
                  "": ConfigProvider.getTranslation("None"),
                }}
                selected={item.rootProvider}
                setSelected={(val) => {
                  setCasinoGamePreferAggregator(item, val);
                  setData((prev) => {
                    const updatedItems = prev.map((subItem) => {
                      if (subItem._id === item._id) {
                        return { ...subItem, rootProvider: val };
                      }
                      return subItem;
                    });
                    return updatedItems;
                  });
                }}
              />
            )}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{item.providerName}</div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center justify-center">
            <span
              className={classNames(
                "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
                item.buyFreespin
                  ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                  : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
              )}
            >
              {item.buyFreespin
                ? ConfigProvider.getTranslation("Active")
                : ConfigProvider.getTranslation("Passive")}
            </span>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="w-full">
            {Object.keys(item.extraCategories).map((key, ixx) => (
              <div key={ixx} className="flex items-center justify-between">
                <span>{item.extraCategories[key]}</span>
              </div>
            ))}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="w-full">
            {Object.keys(item.pages).map((key, ixx) => (
              <div key={ixx} className="flex items-center justify-between">
                <span>{item.pages[key]}</span>
              </div>
            ))}
          </div>
        </td>
      </tr>
    </tbody>
  );
}
export default CasinoGamePrefers;
