import React, { createContext, useContext, useEffect, useState } from "react";
import { usePrefences } from "../context/PrefencesContext";
const TimezoneContext = createContext();

export function TimezoneProvider({ children }) {
  const PrefencesProvider = usePrefences();
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => new Date(prevTime.getTime() + 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const exportClock = new Intl.DateTimeFormat("default", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: PrefencesProvider.prefences.timezone,
  }).format(time);
  const exportShortClock = new Intl.DateTimeFormat("default", {
    hour: "numeric",
    minute: "numeric",
    timeZone: PrefencesProvider.prefences.timezone,
  }).format(time);

  return (
    <TimezoneContext.Provider
      value={{
        time,
        setTime,
        exportClock,
        exportShortClock,
      }}
    >
      {children}
    </TimezoneContext.Provider>
  );
}

export function useTimezone() {
  return useContext(TimezoneContext);
}
