import React, { useState } from "react";
import { useDeviceSize } from "../../context/DeviceSizeContext";
import TreeComponent from "./TreeComponent";
import { classNames } from "../../helpers/Helpers";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";

function TreeMain({ callback, enableDnd = undefined }) {
  const DeviceSizeContext = useDeviceSize();
  const [showTree, setShowTree] = useState(
    window.innerWidth < 1280 ? false : true
  );

  return DeviceSizeContext.deviceType === "lg" ? (
    <>
      <div className="border-r pr-2 border-slate-200 dark:border-slate-600 min-h-[calc(100vh-130px)] relative">
        {showTree && (
          <div className="min-w-[300px] max-w-[300px] sticky top-20 overflow-y-auto max-h-[calc(100vh-138px)]">
            <TreeComponent callback={callback} enableDnd={enableDnd} />
          </div>
        )}
        <button
          type="button"
          onClick={() => setShowTree((prev) => !prev)}
          className={classNames(
            "btn bg-indigo-400 text-slate-100 fixed z-10",
            !showTree
              ? "top-1/2 ml-[8px] transform -translate-x-1/2 -translate-y-1/2"
              : "top-1/2 ml-[285px] rotate-180"
          )}
        >
          <ChevronDoubleRightIcon className="w-4 h-4 shrink-0 " />
        </button>
      </div>
    </>
  ) : null;
}

export default TreeMain;
