import React, { createContext, useContext, useState } from "react";
import { useConfig } from "../context/ConfigContext";

const PrefencesContext = createContext();

export function PrefencesProvider({ children }) {
  const validValue = (key, val) => {
    if (
      key === "timezone" &&
      ConfigProvider.config.timezones.hasOwnProperty(val)
    ) {
      return true;
    } else if (key === "offset" && val > 0 && val <= 100) {
      return true;
    } else if (key === "audio" && (val === 0) | (val === 1)) {
      return true;
    } else return false;
  };
  const ConfigProvider = useConfig();

  let defaultPrefences = {
    timezone: ConfigProvider.config.defaultTimezone,
    offset: 20,
    audio: 0,
  };
  Object.keys(defaultPrefences).forEach((item) => {
    if (localStorage.getItem(item)) {
      try {
        defaultPrefences[item] = JSON.parse(localStorage.getItem(item));
      } catch (ex) {}
    }
  });
  const [prefences, setPrefences] = useState(defaultPrefences);

  const savePrefences = (keys, value) => {
    if (validValue(keys, value)) {
      setPrefences((prevObject) => {
        return { ...prevObject, [keys]: value };
      });
      localStorage.setItem(keys, JSON.stringify(value));
    }
  };

  return (
    <PrefencesContext.Provider value={{ prefences, savePrefences }}>
      {children}
    </PrefencesContext.Provider>
  );
}

export function usePrefences() {
  return useContext(PrefencesContext);
}
