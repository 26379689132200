import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon, CalendarIcon } from "@heroicons/react/24/solid";
import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";

import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useToastr } from "../../context/ToastrContext";

function SettlementEvent() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const ToastrContext = useToastr();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SettlementEventSettlement")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const { _id } = useParams();
  const [loading, setLoading] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/resulting/getResultEvent", { id: _id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setEventInfo(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  const setResult = (e) => {
    e.preventDefault();

    if (!loading && eventInfo) {
      setLoading(true);
      const postData = { eid: _id, r: [] };
      eventInfo.markets.forEach((element) => {
        const mid = element.mid;
        Object.keys(element.odds).forEach((pid) => {
          postData.r.push({ mid, pid, status: element.odds[pid].status });
        });
      });

      Requests.postData("/resulting/setResult", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="md:flex md:space-x-4">
      <div className="border-b md:border-r md:border-b-0 md:pr-2 border-slate-200 dark:border-slate-700 md:min-h-[calc(100vh-130px)] relative">
        <div className="w-full md:min-w-[300px] md:max-w-[300px] md:sticky md:top-20 md:overflow-y-auto md:max-h-[calc(100vh-138px)]">
          <div>
            <div className="w-full">
              <div className="flex items-center min-w-0 truncate mb-1 text-lg text-indigo-500">
                {eventInfo && eventInfo.label}
              </div>
              <div className="flex items-center min-w-0 truncate text-sm mb-1 text-slate-700 dark:text-slate-500">
                {`${eventInfo && eventInfo.countryName} / ${
                  eventInfo && eventInfo.leagueName
                }`}
              </div>
              <div className="flex items-center min-w-0 truncate text-sm mb-5">
                <CalendarIcon className="w-4 h-4 mr-2" />
                {eventInfo &&
                  new Intl.DateTimeFormat("default", {
                    day: "numeric",
                    month: "numeric",
                    hour: "numeric",
                    year: "numeric",
                    minute: "numeric",
                    timeZone: PrefencesProvider.prefences.timezone,
                  }).format(new Date(eventInfo.eventdate))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative grow min-w-0 py-4 md:py-0">
        {loading && <div className="w-full h-full absolute z-50"></div>}
        <form className="w-full" autoComplete="off" onSubmit={setResult}>
          <div className="overflow-x-auto">
            <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
              {/* Table header */}
              <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
                <tr>
                  <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Market")}
                    </div>
                  </th>
                  <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Result")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                {!loading && eventInfo && eventInfo.markets.length === 0 && (
                  <tr>
                    <td colSpan="2">
                      <NoData />
                    </td>
                  </tr>
                )}
                {loading && (
                  <tr>
                    <td colSpan="2" className="text-center py-10">
                      <LoadingSpinner className="w-12 h-12" />
                    </td>
                  </tr>
                )}
                {!loading &&
                  eventInfo &&
                  eventInfo.markets.map((item) => (
                    <tr
                      key={item.mid}
                      className={`${
                        Object.values(item.odds).find((x) => x.status === 0)
                          ? "bg-slate-200 dark:bg-slate-700/80"
                          : "bg-indigo-200 dark:bg-indigo-500/75 dark:text-slate-100"
                      }`}
                    >
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-left">{item.marketName}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-left">
                          {Object.keys(item.odds).map((key) => (
                            <OddRender
                              key={key}
                              mKey={key}
                              item={item.odds[key]}
                              change={(val) => {
                                setEventInfo((prev) => {
                                  const updatedMarkets = prev.markets.map(
                                    (market) => {
                                      if (market.mid === item.mid) {
                                        return {
                                          ...market,
                                          odds: {
                                            ...market.odds,
                                            [key]: {
                                              ...market.odds[key],
                                              status: val,
                                            },
                                          },
                                        };
                                      }
                                      return market;
                                    }
                                  );

                                  return {
                                    ...prev,
                                    markets: updatedMarkets,
                                  };
                                });
                              }}
                            />
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <footer>
            <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
              <div className="flex self-end">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                >
                  <span className="mr-2 uppercase">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-4 h-4 mb-1" />
                    </span>
                  ) : (
                    <span>
                      <ArrowRightCircleIcon className="w-4 h-4" />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </footer>
        </form>
      </div>
    </div>
  );
}
function OddRender({ mKey, item, change }) {
  const ConfigProvider = useConfig();
  return (
    <div className="flex flex-col w-full space-y-2 py-1">
      <div>{item.name}</div>
      <div>
        <select
          className="btn w-full min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
          value={item.status}
          onChange={(e) => change(e.target.value)}
          required
        >
          <option value={0}>{ConfigProvider.getTranslation("Pending")}</option>
          <option value={1}>{ConfigProvider.getTranslation("Won")}</option>
          <option value={4}>{ConfigProvider.getTranslation("HalfWon")}</option>
          <option value={2}>{ConfigProvider.getTranslation("Lost")}</option>
          <option value={3}>{ConfigProvider.getTranslation("Return")}</option>
          <option value={5}>{ConfigProvider.getTranslation("HalfLost")}</option>
        </select>
      </div>
    </div>
  );
}

export default SettlementEvent;
